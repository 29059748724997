@import '@scania/theme-light/dist/styles/global-style';

.sdds-header {
    background-color: var(--sdds-white) !important;
    height: 16rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid var(--sdds-grey-200) !important;
    color: var(--sdds-grey-600) !important;
    box-sizing: content-box;
}

.uppercase {
    text-transform: uppercase;
}

.padding {
    padding: 20px !important;
}

.text-center {
    text-align: center;
}

.uppercase {
    text-transform: uppercase;
}

.border {
    border: 1px solid #ddd;
    padding: 30px 0;
    margin-bottom: 15px;
}

.scrollable {
    max-height: calc(100vh - 210px);
    overflow-y: scroll;
}

.float-left {
    text-align: left;
}

.no-padding{
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.remove-right-padding{
    padding-right: 0 !important;
}

.border-top{
    border-top: 1px solid #ddd;
}

.border-bottom{
    border-bottom: 1px solid #ddd;
}

.padding-bottom {
    padding-bottom: 20px !important;
}

.padding-left {
    padding-left: 4px;
}

.margin-top-none {
    margin-top: 0 !important;
}

.margin-top {
    margin-top: 20px !important;
}

.margin-bottom {
    margin-bottom: 20px !important;
}

.margin {
    margin: 20px !important;
}

.float-right {
    float: right;
}

.text-rigth {
    text-align: right;
}

.height-100 {
    height: 100%;
}

.hyphenate {
    word-wrap: break-word;
    overflow-wrap: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
    .hidden-xxlg {
        display: flex;
    }
    
    .hidden-xlg {
        display: flex;
    }
    
    .hidden-lg {
        display: flex;
    }
    
    .hidden-md {
        display: flex;
    }

    .hidden-sm {
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .hidden-xxlg {
        display: flex;
    }
    
    .hidden-xlg {
        display: flex;
    }
    
    .hidden-lg {
        display: none;
    }
    
    .hidden-md {
        display: none;
    }

    .hidden-sm {
        display: flex;
    }
}

@media only screen and (min-width: 992px) {
    .hidden-xxlg {
        display: flex;
    }
    
    .hidden-xlg {
        display: flex;
    }
    
    .hidden-lg {
        display: none;
    }
    
    .hidden-md {
        display: flex;
    }

    .hidden-sm {
        display: flex;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .hidden-xxlg {
        display: none;
    }
    
    .hidden-xlg {
        display: none;
    }
    
    .hidden-lg {
        display: none;
    }
    
    .hidden-md {
        display: flex;
    }

    .hidden-sm {
        display: flex;
    }
}